@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header{
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.486),black), url("images/terraplus_treeplanting_exercise_image_3.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-card{
  width: 100%;
  height: 400px;
  background: url("images/terraplus_treeplanting_exercise_image_1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mission-vision li, .membership li{
  list-style-type: disc;
}

.button-primary:hover{
  box-shadow: 0px 5px 15px 0px rgba(39,48,112,0.51);
}

.button-secondary:hover{
  box-shadow: 0px 5px 15px 0px rgba(112, 101, 39, 0.51);
}

.integrity-card,.collaboration-card,.commitment-card,.deligence-card,.transparency-card,.innovation-card{
  background: linear-gradient(rgba(0, 0, 0, 0.493), rgba(0, 0, 0, 0.712)), url("images/terraplus_treeplanting_exercise_image_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: all 1s linear;
}


.core-card{
  border: none !important;
  cursor: pointer;
  overflow: hidden;
}

.core-card:hover p{
  display: flex;
  padding-bottom: 2rem;
  transition: all 1s linear;
  opacity: 1;
}

.core-card:hover > div,
.core-card:focus{
  transform: scale(1.1);
  transition: all 1s linear ;
  box-shadow: 2px 2px 12px  gray;
}

.page-title{
  background: linear-gradient(to top,#0284c7,#0285c759), url("images/terraplus_treeplanting_exercise_image_1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width:100vw;
}